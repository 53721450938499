
const isNativeMobileWebView = () => {
  //@ts-ignore
  const standalone = window.navigator?.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && !safari) {
      // iOS webview
      return true;
    } else {
      return false;
    }
  } else {
    if (userAgent.includes('wv')) {
      // Android webview
      return true;
    } else {
      return false;
    }
  }
};

export default isNativeMobileWebView;
